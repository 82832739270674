<template>
    <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" style="fill: currentColor">
        <g clip-path="url(#clip0_606_849)">
            <path d="M14 12V12.6667C14 13.4 13.4 14 12.6667 14H3.33333C2.59333 14 2 13.4 2 12.6667V3.33333C2 2.6 2.59333 2 3.33333 2H12.6667C13.4 2 14 2.6 14 3.33333V4H8C7.26 4 6.66667 4.6 6.66667 5.33333V10.6667C6.66667 11.4 7.26 12 8 12H14ZM8 10.6667H14.6667V5.33333H8V10.6667ZM10.6667 9C10.1133 9 9.66667 8.55333 9.66667 8C9.66667 7.44667 10.1133 7 10.6667 7C11.22 7 11.6667 7.44667 11.6667 8C11.6667 8.55333 11.22 9 10.6667 9Z"/>
        </g>
        <defs>
            <clipPath id="clip0_606_849">
                <rect width="16" height="16"/>
            </clipPath>
        </defs>
    </svg>
</template>